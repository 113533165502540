import React, { useEffect, useState } from 'react';
import { RideList_element } from '../rideList_element/RideList_element';
import { Paginator } from '../paginator/Paginator';

import './RideList_list.scss';

function RideList_list(props) {
	const { rideData, loading, setAdaptive, showConfig, timeInterval, setTimeInterval} = props;
	const dataPerPage = 60;

	const [currentPage, setCurrentPage] = useState(1);
	const [currentData, setCurrentData] = useState([]);
	const [data, setData] = useState([]);
	const [highlightedItemId, setHighlightedItemId] = useState(null);
	const [light, setLight] = useState([]);

	const Paginate = pageNumber => setCurrentPage(pageNumber);

	function getRandomInt(min, max) {

		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
	}

	const getMin = (min) => {
		min = min - 1
		return min
	}

	const getMax = (max) => {
		max = max - 1
		return max
	}

	useEffect(() => {
		if (rideData) {
			setData(rideData);
			setCurrentData(rideData.slice(0, dataPerPage));
			setLight(rideData)
		} else {
			console.log('no-data');
		}
		let min = rideData.length
		let max = rideData.length - 1
		let idCount = 0
		const interval = setInterval(() => {
			min >= 0 ? min = min - 1 : min = min + 1
			max >= 0 ? max = max - 1 : max = max + 1
			idCount = idCount + 1
			rideData.at(getRandomInt(min, max)).id = rideData.length + idCount
			setData(oldData => [
				// {
				// 	id: oldData.length + 1,
				// 	vehicle: 'X645KY178',
				// 	Entry_date: '17.07.23',
				// 	Entry_time: '16:26:51',
				// 	Exit_date: '12.08.23',
				// 	Exit_time: '14:47:08',
				// 	duration: '13д. 22ч 20мин',
				// 	weight: '23.5 T.',
				// 	volume: 'https://dquarry.vaint.ru/media/volume.png',
				// 	road: 'https://dquarry.vaint.ru/media/route_old.png,https://dquarry.vaint.ru/media/route_new.png',
				// 	link_to_photo:
				// 		'https://careers.cgeng.pro/media/pdf-1_1.png,https://careers.cgeng.pro/media/pdf-1_2.png',
				// },
				
				rideData.at(getRandomInt(min, max)),
				...oldData,
			]);
		}, 15000);

		const interval2 = setInterval(() => {
			setLight(oldData => [
				{
					id: oldData.length + 1,
					vehicle: 'X645KY178',
					Entry_date: '17.07.23',
					Entry_time: '16:26:51',
					Exit_date: '12.08.23',
					Exit_time: '14:47:08',
					duration: '13д. 22ч 20мин',
					weight: '23.5 T.',
					volume: 'https://dquarry.vaint.ru/media/volume.png',
					road: 'https://dquarry.vaint.ru/media/route_old.png,https://dquarry.vaint.ru/media/route_new.png',
					link_to_photo:
						'https://careers.cgeng.pro/media/pdf-1_1.png,https://careers.cgeng.pro/media/pdf-1_2.png',
				},
				...oldData,
			]);
		}, 15000);

		console.log(data.length)
		console.log(light.length)

		return () => {
			clearInterval(interval) 
			clearInterval(interval2)
		};
	}, []);

	useEffect(() => {
		const lastElementIndex = currentPage * dataPerPage;
		const firstElementIndex = lastElementIndex - dataPerPage;
		setCurrentData(data.slice(firstElementIndex, lastElementIndex));
	}, [currentPage, data]);

	useEffect(() => {
		console.log('ooo', data.length)
		data.length > 20 && setHighlightedItemId(data.at(0).id);
		if (highlightedItemId) {
			setTimeout(() => {
				setHighlightedItemId(null);
			}, 15000); // время подсветки элемента
		}
	}, [data.length]);

	let Today = new Date()
	let day = String(Today.getDate())
	let month = String(Today.getMonth() + 1).padStart(2, '0')
	let year = String(Today.getFullYear()).slice(2,4)

	useEffect(() => {

		switch(timeInterval) {
			case 'today':  
				let filteredData = rideData.filter((elem) => (elem.Exit_date === `${day}.${month}.${year}`))
				setData(filteredData)
				console.log('done')
				console.log(rideData, 'after today')
				break
		  
			case 'week':  
				let weekData = rideData.filter((elem) => 
					( 
					Number(`${day}`) - Number(elem.Exit_date.slice(0,2)) <= 7 
					&& Number(`${day}`) - Number(elem.Exit_date.slice(0,2)) >= 0
					) 
					&& elem.Exit_date.slice(3,8) === `${month}.${year}`
				)
				weekData.length > 2 ? setData(weekData) : console.log(weekData)
				console.log('done')
				console.log(rideData, 'after done')
				break

			case 'month':  
			setData(rideData)
				let monthData = rideData.filter((elem) => 
				( 
					(Number(`${day}`) - Number(elem.Exit_date.slice(0,2)) >= 0 && elem.Exit_date.slice(3,8) === `${month}.${year}`)
					||
					(Number(elem.Exit_date.slice(0,2) >= Number(`${day}`) && Number(elem.Exit_date.slice(0,2) <= 31))) && (elem.Exit_date.slice(3,8) === `${'0' + String(Number(month) - 1)}.${year}`)
					//elem.Exit_date <= `${day}.${month}.${year}` && elem.Exit_date <= `${day}.${Number(month) - 1}.${year}`
					// ((Number(`${day}`) - Number(elem.Exit_date.slice(0,2)) < Number(`${day}`)) && (elem.Exit_date.slice(3,8) === `${month}.${year}`))
					// || 
					// ((Number(elem.Exit_date.slice(0,2)) - Number(`${day}`) >= 0) && (elem.Exit_date.slice(3,8) === `${Number(month) - 1}.${year}`))
				) 
				)
				setData(monthData)
				console.log('done')
				console.log(rideData, 'after done')
				break
		  
			default:
				setData(rideData)
		}

	  }, [timeInterval])

	return (
		<div className='ridelistlist' onClick={() => setAdaptive(false)}>
			{currentData.map(elem => (
				<RideList_element
					key={elem.id}
					id={elem.id}
					number={elem.vehicle}
					enter={elem.Entry_date}
					enter_time={elem.Entry_time}
					exit={elem.Exit_date}
					exit_time={elem.Exit_time}
					duration={elem.duration}
					weight={elem.weight}
					volume={elem.volume}
					road={elem.road}
					photo={elem.link_to_photo}
					loading={loading}
					showConfig={showConfig}
					highlighted={elem.id === highlightedItemId}
					volume_count={elem.volume_count}
				/>
			))}
			<Paginator
				dataPerPage={dataPerPage}
				totalData={data.length}
				paginate={Paginate}
				currentPage={currentPage}
			/>
		</div>
	);
}

export { RideList_list };
