import React, { useEffect, useState } from 'react'
import { Cabinet } from '../../components/cabinet/Cabinet'
import { RideList_title } from '../../components/rideList_title/RideList_title'
import { RideList_sctruct } from '../../components/rideList_structure/RideList_sctruct'
import { RideList_list } from '../../components/rideList_list/RideList_list'
import { GetRideData } from '../../requests/GetRideData'

import './RideList.scss'


function RideList({adaptive, setAdaptive}) {

  const [rideData, setRideData] = useState(['none'])
  const [loading, setLoading] = useState(false)

  const [firstData, setFirstData] = useState([])

  const [counter, setCounter] = useState(0)

  const [showFilter, setShowFilter] = useState(false)


  const [timeInterval, setTimeInterval] = useState('all')

  const setInterval = (val) => {
    setTimeInterval(val)
  }

  let allData = []

  const [showConfig, setShowConfig] = useState({
      id: true,
      number: true,
      enter: true,
      exit: true,
      duration: true,
      weight: true,
      volume: false,
      road: false,
      photo: true
  })


  // useEffect(() => {
  //   let tempData = [...rideData]
  //   tempData.splice(100,rideData.length)
  //   setFirstData(tempData)
  //   console.log(firstData, 'fd')
  // }, [firstData])

  // useEffect(() => {
  //   let tempAllData = [...rideData]
  //   tempAllData.splice(0,99)
  //   setAllData(tempAllData)
  //   console.log(allData, 'a d')
  // }, [allData])
  
  // useEffect(() => {
    
  //   setInterval(() => {
      
  //     setRideData(rideData => [
  //       allData[0]
  //     , ...rideData])
  //     allData.splice(0,1)
  //   }, 5000);
  // }, [])

  let Today = new Date()
  let day = String(Today.getDate())
  let month = String(Today.getMonth() + 1).padStart(2, '0')
  let year = String(Today.getFullYear()).slice(2,4)

  console.log(timeInterval)

  useEffect(() => {
    
    try {
      setLoading(true)
      let data = fetch(window.urlFetch, {
        method: "GET", 
        mode: "no-cors", 
        credentials: 'same-origin',
        headers: {'Access-Control-Allow-Origin': '*'}
        }).then(response => response.json()).then(response => {
          setRideData(response.reverse())
        })
        console.log(data, 'transport data');
      setLoading(false)

      console.log(rideData, 'from RideList')

      //`${day}.${month}.${year}`

      //console.log(showConfig)

      // let data = require('../../data/result.json')

      // setRideData(data.reverse())

      // if (timeInterval === 'today'){

      //   let filteredData = data.filter((elem) => (elem.Exit_date === `${day}.${month}.${year}`))
      //   setRideData(filteredData)
      //   console.log('done')
      //   console.log(rideData, 'after today')

      // } else if (timeInterval === 'week'){

      //   let filteredData = data.filter((elem) => 
      //     ( 
      //       Number(`${day}`) - Number(elem.Exit_date.slice(0,2)) <= 7 
      //       && Number(`${day}`) - Number(elem.Exit_date.slice(0,2)) >= 0
      //     ) 
      //     && elem.Exit_date.slice(3,8) === `${month}.${year}`
      //   )
      //   setRideData(filteredData)
      //   console.log('done')
      //   console.log(rideData, 'after done')

      // } else if (timeInterval === 'month'){

      //   let filteredData = data.filter((elem) => 
      //     ( 
      //       (Number(`${day}`) - Number(elem.Exit_date.slice(0,2)) >= 0 && elem.Exit_date.slice(3,8) === `${month}.${year}`)
      //       ||
      //       (Number(elem.Exit_date.slice(0,2) >= Number(`${day}`) && Number(elem.Exit_date.slice(0,2) <= 31))) && (elem.Exit_date.slice(3,8) === `${'0' + String(Number(month) - 1)}.${year}`)
      //       //elem.Exit_date <= `${day}.${month}.${year}` && elem.Exit_date <= `${day}.${Number(month) - 1}.${year}`
      //       // ((Number(`${day}`) - Number(elem.Exit_date.slice(0,2)) < Number(`${day}`)) && (elem.Exit_date.slice(3,8) === `${month}.${year}`))
      //       // || 
      //       // ((Number(elem.Exit_date.slice(0,2)) - Number(`${day}`) >= 0) && (elem.Exit_date.slice(3,8) === `${Number(month) - 1}.${year}`))
      //     ) 
      //   )
      //   setRideData(filteredData)
      //   console.log('done')
      //   console.log(rideData, 'after done')

      // } else if (timeInterval === 'all'){

      //   let filteredData = data
      //   setRideData(filteredData)
      //   console.log('done')
      //   console.log(rideData, 'after done')
        
      // }

      setLoading(false)
    } catch (error) {
      throw new Error(`ERROR ${error}`)
    }
  })

  

  return (
    <div className="ridelist">
      <Cabinet/>
      <RideList_title adaptive={adaptive} setAdaptive={setAdaptive} timeInterval={timeInterval} setTimeInterval={setTimeInterval} showFilter={showFilter} setShowFilter={setShowFilter}/>
      <RideList_sctruct showConfig={showConfig} setShowConfig={setShowConfig} showFilter={showFilter} setShowFilter={setShowFilter}/>
      {
        rideData.length > 2 ? 
        <RideList_list timeInterval={timeInterval} setTimeInterval={setTimeInterval} rideData={rideData} showConfig={showConfig} loading={loading} setAdaptive={setAdaptive}/> 
        : 
        <h1>Loading data</h1>
      }
      
    </div>
  )
}

export {RideList}